













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ImageView extends Vue {
  @Prop({ type: String }) file!: string;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
}
