


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import pdf from 'vue-pdf';

@Component({
  components: { pdf }
})
export default class ViewFileDialog extends Vue {
  @Prop({ type: String }) file!: string;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  fileTask: { promise: Promise<{ numPages: number }> } | null = null;
  pageCount: number = 0;

  @Watch('file', { immediate: true })
  async watchFolder(file: string) {
    if (!file) {
      this.fileTask = null;
      this.pageCount = 0;

      return;
    }

    this.fileTask = pdf.createLoadingTask(file);

    if (this.fileTask) {
      const { numPages } = await this.fileTask.promise;

      this.pageCount = numPages;
    }
  }
}
